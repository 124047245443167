import * as React from "react";
import { CircularProgress, Box } from '@material-ui/core'
import { ProductList, Page, Layout } from "../components";
import { useAllProductsData } from '../helpers';
import { Router, useMatch } from '@reach/router';

const bgimage = 'https://images.unsplash.com/photo-1511335413948-b5966e4e1d01?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1052&q=80'

const Products = (props) => {
  const [breadcrumbLink, seBreadcrumbLink] = React.useState('');
  const allProducts = useAllProductsData();
  console.log('allProducts', allProducts);
  console.log('props', props);
  let categoryId = props?.location?.search.slice(10);
  let subCategoryId = props?.id;
  const pageLoading = allProducts?.listOfProductsLoading;
  const pageData = allProducts?.listOfProducts
  const match = useMatch('/products/:CategoryId/:SubCategoryId')
  const matchUnderCategories = useMatch('/products/:CategoryId')
  const productCategories = allProducts?.mainSubCategoryData.map(l => l.Category).flat(1) || [];
  const _categoryId = match?.CategoryId || matchUnderCategories?.CategoryId
  const productCategory = productCategories?.find(l => l.CategoryId === parseInt(_categoryId));
  const productSubCategory = productCategory?.SubCategory?.find(l => l.SubCategoryId === parseInt(match?.SubCategoryId));
  const title = `${productCategory?.Category} ${productSubCategory?.SubCategory ? `- ${productSubCategory?.SubCategory}` : ''}`;
  const menuData = allProducts?.mainSubCategoryData.find(l => productCategory?.MenuId === l?.MenuId)
  const ids = {
    CategoryId: _categoryId,
    SubCategoryId: match?.SubCategoryId || 0,
  }
  const fetchProducts = async () => {
    // console.log("working!!!!")
    await allProducts?.getListOfProducts(ids);
  }

  React.useEffect(() => {
    if (ids.CategoryId) {
      fetchProducts();
      seBreadcrumbLink({
        MenuName: menuData?.MenuName,
        MenuId: menuData?.MenuId,
        Category: productCategory?.Category,
        CategoryId: productCategory?.CategoryId,
        SubCategory: productCategory?.Productexists === 1 ? undefined : productSubCategory?.SubCategory,
        SubCategoryId: productCategory?.Productexists === 1 ? undefined : productSubCategory?.SubCategoryId,
      })
    }
  }, [props, menuData])

  console.log('productCategory', productCategory)
  console.log('productSubCategory', productSubCategory)
  console.log('menuData', menuData)

  return (
    <Layout>
      <Page>
        {/* {props.id} */}
        {console.log('pageLoading', pageLoading)}
        {console.log('pageData', pageData)}
        <Router>
          <ProductList
            path={match ? '/products/:CategoryId/:SubCategoryId' : '/products/:CategoryId'}
            data={pageData}
            listPage
            loading={pageLoading}
            bgImage={bgimage}
            title={title}
            subTitle='Top view in this week'
            ids={ids}
            breadcrumbLink={breadcrumbLink}
          />
        </Router>
      </Page>
    </Layout>
  )
}

export default Products
